import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import ScrollContainer from 'react-indiana-drag-scroll';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowBack from '../assets/icons/arrow-back.svg';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import NotFound from '../components/NotFound';
import ObserverSection from '../components/ObserverSection';
import ImageRevealer from '../components/ImageRevealer';

const CandidateTemplate = ({ data }) => {
  const intl = useIntl();
  const { locale } = intl;

  const [galleryDragged, setGalleryDragged] = useState(false);

  const { candidate, editions = [] } = data;

  let edition = [];
  if (candidate?.categories?.nodes && candidate.categories.nodes.length !== 0) {
    edition = editions.nodes.filter(
      el => el.categories?.nodes[0]?.slug === candidate.categories.nodes[0].slug
    );
  }

  if (edition.length === 0 || !edition[0].editionSettings?.active) {
    return <NotFound />;
  }

  return (
    <Layout>
      <Seo title={candidate?.candidate?.fullName} />
      <ObserverSection className="candidate-presentation-section">
        <div
          className="candidate-profile-picture"
          style={{
            backgroundImage: `url(${
              candidate?.candidate?.profilePicture?.localFile?.url
            }), url(${
              getImage(candidate?.candidate?.profilePicture?.localFile)
                ?.placeholder?.fallback
            })`,
          }}
        />
        <div className="candidate-description-wrapper">
          <div className="candidate-edition">
            {locale === 'en' && edition[0].editionSettings?.nameEn
              ? edition[0].editionSettings.nameEn
              : edition[0].editionSettings.nameFr}
          </div>
          <div className="candidate-full-name">
            {candidate?.candidate?.fullName}
          </div>
          <div className="candidate-decription">
            {locale === 'en' && candidate?.candidate?.descriptionEn
              ? candidate.candidate.descriptionEn
              : candidate.candidate.descriptionFr}
          </div>
          {candidate?.candidate?.lookAndMaterials?.show ? (
            <>
              <div
                className="candidate-full-name"
                style={{ marginTop: '20px' }}
              >
                {locale === 'fr' && candidate.candidate.lookAndMaterials.titleFr
                  ? candidate.candidate.lookAndMaterials.titleFr
                  : candidate.candidate.lookAndMaterials.titleEn}
              </div>
              <div className="candidate-decription">
                {locale === 'fr' &&
                candidate.candidate.lookAndMaterials.descriptionFr
                  ? candidate.candidate.lookAndMaterials.descriptionFr
                  : candidate.candidate.lookAndMaterials.descriptionEn}
              </div>
            </>
          ) : null}
          {candidate?.candidate?.careInstructions?.show ? (
            <>
              <div
                className="candidate-full-name"
                style={{ marginTop: '20px' }}
              >
                {locale === 'fr' && candidate.candidate.careInstructions.titleFr
                  ? candidate.candidate.careInstructions.titleFr
                  : candidate.candidate.careInstructions.titleEn}
              </div>
              <div className="candidate-decription">
                {locale === 'fr' &&
                candidate.candidate.careInstructions.descriptionFr
                  ? candidate.candidate.careInstructions.descriptionFr
                  : candidate.candidate.careInstructions.descriptionEn}
              </div>
            </>
          ) : null}
        </div>
      </ObserverSection>
      <ObserverSection>
        <Link
          to={edition[0]?.editionSettings?.url}
          className="edition-go-back-button"
        >
          <ArrowBack />
          {intl.formatMessage({ id: 'editions.goToEdition' })}
        </Link>
      </ObserverSection>
      <ObserverSection className="candidate-gallery-mobile">
        {candidate?.candidate?.photos &&
          candidate?.candidate?.photos.length > 0 &&
          candidate?.candidate?.photoscandidate?.candidate?.photos.map(
            photo => (
              <GatsbyImage
                alt=""
                image={getImage(photo.localFile)}
                key={photo.id}
                className="candidate-gallery-item"
              />
            )
          )}
      </ObserverSection>
      <ObserverSection className="candidate-gallery-desktop">
        <ScrollContainer
          vertical={false}
          className="candidate-gallery-scroll-wrapper draggable-container"
          onScroll={() => {
            if (!galleryDragged) {
              setGalleryDragged(true);
            }
          }}
        >
          <div className="candidate-gallery-items-wrapper">
            {candidate?.candidate?.photos &&
              candidate?.candidate?.photos.length > 0 &&
              candidate?.candidate?.photos.map(photo => (
                <ImageRevealer>
                  <GatsbyImage
                    alt=""
                    image={getImage(photo.localFile)}
                    key={photo.id}
                    className="candidate-gallery-item"
                  />
                </ImageRevealer>
              ))}
          </div>
        </ScrollContainer>
        <div
          className={`drag-indicator ${galleryDragged ? 'hidden' : 'visible'}`}
        />
      </ObserverSection>
    </Layout>
  );
};

export default CandidateTemplate;

export const pageQuery = graphql`
  query candidateById($id: String!) {
    candidate: wpCandidate(id: { eq: $id }) {
      slug
      categories {
        nodes {
          slug
        }
      }
      id
      candidate {
        photos {
          id
          localFile {
            url
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 512)
            }
          }
        }
        fullName
        descriptionEn
        descriptionFr
        profilePicture {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 512)
            }
          }
          altText
        }
        lookAndMaterials {
          titleFr
          titleEn
          show
          descriptionFr
          descriptionEn
        }
        careInstructions {
          titleFr
          titleEn
          show
          descriptionFr
          descriptionEn
        }
      }
    }
    editions: allWpEdition {
      nodes {
        editionSettings {
          nameEn
          nameFr
          url
          active
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
  }
`;
